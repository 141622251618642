import { useMemo } from 'react'

import { AnimatedDigit } from 'components/TransitionNumber/AnimatedDigit'

import classes from './styles.module.scss'

type Props = {
  numberToRoll: string
  height: number
}

export default function RollNumber({ numberToRoll, height }: Props) {
  const animateToNumbersArr = useMemo(
    () => Array.from(numberToRoll, Number).map((x, idx) => (isNaN(x) ? numberToRoll[idx] : x)),
    [numberToRoll]
  )

  return (
    <div className={classes.root} aria-label={numberToRoll}>
      {animateToNumbersArr.map((digit, index) =>
        typeof digit === 'string' ? (
          <span key={index}>{digit}</span>
        ) : (
          <div key={index} className={classes.cell} style={{ height: `${height}px` }}>
            <AnimatedDigit height={height} number={digit} />
          </div>
        )
      )}
    </div>
  )
}
