import type { Currencies } from '@patrianna/shared-patrianna-types/store/CurrenciesModule'
import type {
  GetAccountJackpotInfoRequest,
  GetJackpotInfoRequest,
  SaveJackpotOptInRequest,
} from '@patrianna/shared-patrianna-types/websocket/requests'
import type {
  GetAccountJackpotInfoResponse,
  GetJackpotInfoResponse,
  SaveJackpotOptInResponse,
} from '@patrianna/shared-patrianna-types/websocket/response'

import type { TypedThunk } from 'store'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { getActiveGameCodeSelector } from 'store/modules/slotGameFlow/selectors'
import type { JackpotInfo } from 'store/types'
import type { JackpotPrize } from 'store/types/JackpotsModule'
import { getSCGrandLastWin } from 'utils/jackpot'

import { openDialog } from '../dialog/actions'

import { actions } from './slice'

const isJackpotEnabled = process.env.JACKPOT_ENABLED

export const {
  setAccountJackpotInfo,
  setJackpotTotals,
  setJackpotPrize,
  setContibutionsJackpot,
  setJackpotUpdateInterval,
  patchJackpotTotals,
  setJackpotOptInIsLoading,
} = actions

export const getAccountJackpotInfo =
  (): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const data: GetAccountJackpotInfoRequest = {
      type: 'jackpot.GetAccountJackpotInfoRequest',
    }

    gateway
      .emit<GetAccountJackpotInfoResponse>(data)
      .then((body) => {
        dispatch(setAccountJackpotInfo(body))
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })
  }

export const getJackpotTotals =
  (): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const data: GetJackpotInfoRequest = {
      type: 'jackpot.GetJackpotInfoRequest',
    }

    gateway
      .emit<GetJackpotInfoResponse>(data)
      .then((body) => {
        dispatch(setJackpotTotals(body.preferences))
        dispatch(setJackpotUpdateInterval(body.updateInterval))
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })
  }

export const saveJackpotOptIn =
  ({ optIn, currency, amount }: { optIn: boolean; currency: Currencies; amount?: number }): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const data: SaveJackpotOptInRequest = {
      type: 'jackpot.SaveJackpotOptInRequest',
      optIn,
      currency,
      ...((amount && { amount }) || {}),
    }

    gateway
      .emit<SaveJackpotOptInResponse>(data)
      .then(() => {
        dispatch(getAccountJackpotInfo())
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })
  }

export const openWonJackpotDialog =
  ({ amount, code, currency, jackpotId }: JackpotPrize): TypedThunk =>
  (dispatch) => {
    if (isJackpotEnabled) {
      const modalName = currency === 'GC' ? 'JACKPOTS_GOLD_WON_DIALOG' : 'JACKPOTS_SWEEPSTAKE_WON_DIALOG'

      dispatch(openDialog({ modalName, dialogProps: { amount, code }, lightBackdropMode: true }))
      dispatch(setJackpotPrize({ amount, code, currency, jackpotId }))
    }
  }

export const openSpinWheelDialog =
  ({ amount, code, currency, jackpotId, spinAmount }: JackpotPrize): TypedThunk =>
  (dispatch) => {
    if (isJackpotEnabled) {
      const modalName = 'JACKPOTS_SPIN_WHEEL_DIALOG'

      dispatch(
        openDialog({
          modalName,
          dialogProps: { amount, code, currency, jackpotId, spinAmount },
          lightBackdropMode: true,
        })
      )
    }
  }

export const openWheelDialog =
  ({ amount, code, currency, jackpotId, spinAmount }: JackpotPrize): TypedThunk =>
  (dispatch) => {
    if (isJackpotEnabled) {
      const modalName = 'JACKPOTS_WHEEL_DIALOG'

      dispatch(
        openDialog({
          modalName,
          dialogProps: { amount, code, currency, jackpotId, spinAmount },
          lightBackdropMode: true,
        })
      )
    }
  }

export const clearJackpotsContributions = (): TypedThunk => (dispatch) => {
  dispatch(setContibutionsJackpot([]))
}

export const openOptInNowDialog =
  (data: JackpotInfo[]): TypedThunk =>
  (dispatch, getState) => {
    if (isJackpotEnabled) {
      const sweepstakeEnabled = sweepstakeEnabledSelector(getState())
      const activeGameCode = getActiveGameCodeSelector(getState())
      const grandSCWinnerInfo = getSCGrandLastWin(data)
      const showOptInNowDialog = sweepstakeEnabled && !activeGameCode && grandSCWinnerInfo && data?.length === 1

      showOptInNowDialog && dispatch(openDialog({ modalName: 'OPT_IN_NOW_DIALOG', dialogProps: { grandSCWinnerInfo } }))
    }
  }
