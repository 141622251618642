import RollNumber from 'components/TransitionNumber/RollNumber'
import useValueTransition from 'components/TransitionNumber/useValueTransition'
import { useInView } from 'hooks/useInView'
import { useAppSelector } from 'store/hooks'
import { getUpdateInterval } from 'store/modules/jackpots/selectors'
import type { JackpotTotals } from 'store/types'

type Props = {
  jackpotRowInfo: JackpotTotals
  sc?: boolean
  height: number
  classNames?: string
  dataTest?: string
}

function TransitionNumber({ jackpotRowInfo, sc, height, classNames, dataTest }: Props) {
  const { inView, ref } = useInView({
    threshold: 0,
    triggerOnce: false,
  })
  const totalDuration = useAppSelector(getUpdateInterval)

  const currentValue = useValueTransition({
    prevAmount: jackpotRowInfo.prevAmount || 0,
    amount: jackpotRowInfo.amount,
    updatedAt: jackpotRowInfo.updatedAt,
    updateInterval: totalDuration,
    inView,
    sc,
  })

  return (
    <div ref={ref} className={classNames} data-test={dataTest}>
      <RollNumber height={height} numberToRoll={currentValue} />
    </div>
  )
}

export default TransitionNumber
