import { isBrowser } from '@patrianna/shared-utils/helpers'
import { differenceInSeconds } from 'date-fns'
import { useEffect } from 'react'
import { usePageVisibility } from 'react-page-visibility'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { isAppInitializedSelector, sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { getJackpotTotals } from 'store/modules/jackpots/actions'
import {
  getGoldJackpotTotalsSelector,
  getSweepstakeJackpotTotalsSelector,
  getUpdateInterval,
} from 'store/modules/jackpots/selectors'

const SECONDS_PER_MINUTE = 60

const useSyncJackpotTotals = () => {
  const dispatch = useAppDispatch()
  const isVisible = usePageVisibility()
  const isExpiredJackpotTotals = useExpiredJackpotTotals()

  useEffect(() => {
    if (isBrowser() && isVisible && isExpiredJackpotTotals) {
      dispatch(getJackpotTotals())
    }
  }, [isVisible, isExpiredJackpotTotals])
}

const getUpdateIntervalInSeconds = (minutes: number) => {
  return minutes * SECONDS_PER_MINUTE
}

const useMaxTimeSinceUpdate = () => {
  const currentTime = new Date().getTime()
  const isScEnabled = useAppSelector(sweepstakeEnabledSelector)
  const goldJackpotTotals = useAppSelector(getGoldJackpotTotalsSelector)
  const sweepstakesJackpotTotals = useAppSelector(getSweepstakeJackpotTotalsSelector)
  const totals = isScEnabled ? [...goldJackpotTotals, ...sweepstakesJackpotTotals] : goldJackpotTotals

  return Math.max(...totals?.map((j) => differenceInSeconds(currentTime, j.updatedAt)))
}

const useExpiredJackpotTotals = () => {
  const appInitialized = useAppSelector(isAppInitializedSelector)
  const updateIntervalInSeconds = getUpdateIntervalInSeconds(useAppSelector(getUpdateInterval))
  const oldestUpdateInSeconds = useMaxTimeSinceUpdate()

  return oldestUpdateInSeconds > updateIntervalInSeconds && process.env.JACKPOT_ENABLED && appInitialized
}

export default useSyncJackpotTotals
