import cx from 'classnames'
import React, { useState, useEffect } from 'react'

import classes from './styles.module.scss'

export const AnimatedDigit = ({ number, height }: { number: number; height: number }) => {
  const [previousNumber, setPreviousNumber] = useState(null)
  const [currentNumber, setCurrentNumber] = useState(number)
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (number !== currentNumber) {
      setPreviousNumber(currentNumber)
      setCurrentNumber(number)
      setAnimate(true)
    }

    // After the animation duration, reset the animate state
    const timeoutId = setTimeout(() => {
      setPreviousNumber(currentNumber)
      setCurrentNumber(currentNumber)
      setAnimate(false)
    }, 200)

    // Clean up the timeout on component unmount or re-render
    return () => clearTimeout(timeoutId)
  }, [number, currentNumber])

  return (
    <div
      className={cx(classes.digit, animate && classes.roll)}
      style={{ transform: `translateY(-${animate ? height : 0}px)` }}
    >
      <span>{previousNumber}</span>
      {currentNumber !== previousNumber && <span>{currentNumber}</span>}
    </div>
  )
}
